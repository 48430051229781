import React from 'react'
import type { FC } from 'react'
import { GatsbySeo, JsonLd } from 'gatsby-plugin-next-seo'
import type { Props } from 'src/pages'
import { Helmet } from 'react-helmet'

import { useMetadata } from './useMetadata'
import { useSiteLinksSearchBoxJsonLd } from './useSiteLinksSearchBoxJsonLd'

const Seo: FC<Props> = (props) => {
  const metadata = useMetadata(props)
  const siteLinksSearchBox = useSiteLinksSearchBoxJsonLd(props)

  return (
    <>
      <Helmet>
        <meta
          name="google-site-verification"
          content="S-rBD1N1hlPGZvyggH0yXKNVKIbsDStZpnvob1pBGdE"
        />
      </Helmet>
      <GatsbySeo {...metadata} defer />
      <JsonLd json={siteLinksSearchBox} defer />
    </>
  )
}

export default Seo

import { styled } from 'src/gatsby-theme-stitches/config'

export const CarouselContainer = styled('div', {
  overflow: 'hidden',
  position: 'relative',

  '.inner': {
    whiteSpace: 'nowrap',
    transition: 'transform 0.3s',
  },

  '&:hover': {
    '.arrows': {
      opacity: '1',
    },
  },
})

export const Item = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#FFF',
  width: '100%',
})

export const ArrowsContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  transition: 'opacity 0.3s',

  button: {
    position: 'absolute',
    top: '50%',
    background: 'transparent',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '$rounded',
    size: '2rem',
    display: 'grid',
    placeItems: 'center',

    '&:last-child': {
      right: '0',
    },

    svg: {
      color: '$white',
    },
  },

  variants: {
    isDesignSystemArrows: {
      true: {
        opacity: '0',

        button: {
          backgroundColor: '$white',
          boxShadow: '0px 4px 5px #00000024',
          border: '1px solid $colors$primary',
          size: '3rem',
          transform: 'translateY(-50%)',

          '&:first-child': {
            left: '7%',
          },

          '&:last-child': {
            right: '7%',
          },

          '&:hover': {
            filter: 'brightness(0.75)',
          },
        },
      },
    },
  },
})

export const Dots = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  width: '100vw',

  button: {
    margin: '.5rem',
    borderRadius: '$rounded',
    border: 'none',
    background: '$lowEmphasis',
    padding: '0.25rem',
    outline: '0',
    size: '$tiny',
    cursor: 'pointer',

    '&.active': {
      background: '$primary',
    },
  },

  variants: {
    isDesignSystemDots: {
      true: {
        button: {
          '&.active': {
            background: '$primary',
          },
        },
      },
    },
  },
})

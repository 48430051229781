import React from 'react'
import { graphql } from 'gatsby'
import type { PageProps } from 'gatsby'
import Layout from 'src/components/Layout'
import HomeView from 'src/views/home'
import Seo from 'src/views/home/Seo'

import type { HomePageQueryQuery } from './__generated__/HomePageQuery.graphql'

export type Props = PageProps<HomePageQueryQuery>

const Page = (props: Props) => {
  return (
    <Layout>
      <Seo {...props} />

      <HomeView {...props} />
    </Layout>
  )
}

export const querySSG = graphql`
  query HomePageQuery(
    $firstShelfCollection: String!
    $firstShelfFrom: Int!
    $firstShelfTo: Int!
    $firstShelfOrderBy: String
    $firstShelfHideUnavailableItems: Boolean!
    $secondShelfCollection: String!
    $secondShelfFrom: Int!
    $secondShelfTo: Int!
    $secondShelfOrderBy: String
    $secondShelfHideUnavailableItems: Boolean!
    $thirdShelfCollection: String!
    $thirdShelfFrom: Int!
    $thirdShelfTo: Int!
    $thirdShelfOrderBy: String
    $thirdShelfHideUnavailableItems: Boolean!
  ) {
    cmsSeo {
      seo {
        facebook {
          title
          description
          thumbnail
        }

        siteMetadata {
          title
          description
          titleTemplate
        }
      }
    }

    cmsHome: cmsHomeDs(name: { eq: "New Home - Design System" }) {
      sections {
        name
        data
      }
    }

    cmsHomeColaborador: cmsHomeDs(name: { eq: "Home Colaborador - DS" }) {
      sections {
        name
        data
      }
    }

    vtex {
      firstShelfProducts: products(
        collection: $firstShelfCollection
        from: $firstShelfFrom
        to: $firstShelfTo
        orderBy: $firstShelfOrderBy
        hideUnavailableItems: $firstShelfHideUnavailableItems
      ) {
        ...ProductSummary_product
      }

      secondShelfProducts: products(
        collection: $secondShelfCollection
        from: $secondShelfFrom
        to: $secondShelfTo
        orderBy: $secondShelfOrderBy
        hideUnavailableItems: $secondShelfHideUnavailableItems
      ) {
        ...ProductSummary_product
      }

      thirdShelfProducts: products(
        collection: $thirdShelfCollection
        from: $thirdShelfFrom
        to: $thirdShelfTo
        orderBy: $thirdShelfOrderBy
        hideUnavailableItems: $thirdShelfHideUnavailableItems
      ) {
        ...ProductSummary_product
      }
    }
  }
`

export default Page

import React, { useMemo } from 'react'
import Carousel, { CarouselItem } from 'src/components/common/Carousel'
import { useGetThumborImageData } from '@vtex/gatsby-plugin-thumbor'
import imagesConf from 'src/images/config'
import { withArtDirection } from 'gatsby-plugin-image'
import { SuspenseViewport } from '@vtex/store-ui'
import { EventPromoClick } from 'src/components/common/EventPromoClick'
import EventPromoView from 'src/views/home/event'

import { CarouselItemImage, CarouselItemLink } from './styled'

interface Props {
  banners: Array<{
    href: string
    alt: string
    sources: Array<{
      media: string
      srcSet: string
    }>
  }>
  isCarouselDesignSystem?: boolean
}

const CarouselBanners = ({ banners, isCarouselDesignSystem }: Props) => {
  const getThumborImageData = useGetThumborImageData()

  const images = useMemo(() => {
    return banners?.map((banner) => {
      const {
        sources: [desktop, mobile],
      } = banner

      const desktopImage = getThumborImageData({
        baseUrl: desktop.srcSet,
        ...imagesConf['carousel.desktop'],
      })

      const mobileImage = getThumborImageData({
        baseUrl: mobile.srcSet,
        ...imagesConf['carousel.mobile'],
      })

      const image = withArtDirection(mobileImage, [
        {
          media: desktop.media,
          image: desktopImage,
        },
      ])

      return {
        ...banner,
        image,
      }
    })
  }, [banners, getThumborImageData])

  return (
    <div>
      <Carousel isCarouselDesignSystem={isCarouselDesignSystem}>
        {images.map((image, index) => (
          <CarouselItem key={image.alt}>
            <CarouselItemLink
              to={image.href}
              title={image.alt}
              onClick={() => {
                EventPromoClick(
                  image?.alt,
                  `Carrossel Banner Principal - ${index + 1}`
                )
              }}
            >
              <CarouselItemImage>
                {image.image.images.sources?.map((source) => (
                  <source key={source.srcSet} {...source} />
                ))}
                <img
                  alt={image.alt}
                  title={image.alt}
                  width={image.image.width}
                  height={image.image.height}
                  {...image.image.images.fallback}
                />
                <SuspenseViewport fallback={null}>
                  <EventPromoView
                    alt={image?.alt}
                    page={`Carrossel Banner Principal - ${index + 1}`}
                  />
                </SuspenseViewport>
              </CarouselItemImage>
            </CarouselItemLink>
          </CarouselItem>
        ))}
      </Carousel>
    </div>
  )
}

export default CarouselBanners

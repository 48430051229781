import { styled } from 'src/gatsby-theme-stitches/config'
import { Link } from 'gatsby'

export const CategoryButtonsContainer = styled('div', {
  display: 'none',

  '@sm': {
    display: 'block',
    marginTop: '2.5rem',
  },
})

export const ButtonsContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '1.5rem',
  overflow: 'auto',
  alignItems: 'flex-start',
  marginTop: '1.25rem',
})

export const CategoryButton = styled(Link, {
  textDecoration: 'none',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '0.5rem',
  fontFamily: '$montserrat',

  '> div': {
    borderRadius: '50%',
    backgroundColor: '$white',
    size: '3rem',
    display: 'grid',
    placeItems: 'center',
    boxShadow: '0px 4px 8px 0px #00000029',
  },

  '> p': {
    fontSize: '.75rem',
    lineHeight: '1.125rem',
    letterSpacing: '.0238rem',
    width: '100%',
    textAlign: 'center',
    color: '$black',
  },
})

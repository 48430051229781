import React, { useState } from 'react'
import ResponsiveImage from 'src/components/ui/ResponsiveImage'
import { useKeenSlider } from 'keen-slider/react'

import { Dots, DotsContainer, RepresentativeCarouselContainer } from './styled'

interface Props {
  banners: Array<{
    href: string
    alt: string
    sources: Array<{
      media: string
      srcSet: string
    }>
  }>
}

const RepresentativeCarousel = ({ banners }: Props) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [sliderRef, instanceRef] = useKeenSlider({
    breakpoints: {
      '(max-width: 767px)': {
        slides: { perView: 1, spacing: 16 },
      },
      '(min-width: 768px)': {
        slides: { perView: 2, spacing: 20 },
      },
      '(min-width: 1024px)': {
        slides: { perView: 2, spacing: 24 },
      },
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
  })

  return (
    <RepresentativeCarouselContainer ref={sliderRef}>
      <div className="keen-slider">
        {banners.map(({ alt, href, sources }) => (
          <a href={href} key={alt} className="keen-slider__slide">
            <ResponsiveImage
              alt={alt}
              desktop={sources[0].srcSet}
              mobile={sources[1].srcSet}
            />
          </a>
        ))}
      </div>

      <DotsContainer role="group" aria-label="Slider pagination dots">
        {[...Array(banners.length).keys()].map((_, index) => (
          <Dots
            isActive={currentSlide === index}
            key={index}
            tabIndex={0}
            onClick={() => instanceRef.current?.moveToIdx(index)}
            role="button"
            aria-label={`Dot ${index + 1}`}
            data-testid="paginationDot"
          />
        ))}
      </DotsContainer>
    </RepresentativeCarouselContainer>
  )
}

export default RepresentativeCarousel

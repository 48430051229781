import { styled } from 'src/gatsby-theme-stitches/config'

export const HomeWrapper = styled('div', {
  backgroundColor: '$background',
})

export const HomeContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '85%',
  maxWidth: '80rem',
  mx: 'auto',

  '@sm': {
    width: '90%',
  },
})

export const SectionTitle = styled('div', {
  fontSize: '$5',
  lineHeight: '$small',
  fontFamily: '$roboto',
  fontWeight: '$medium',
  position: 'relative',
  ml: '1.25rem',
  color: '$onBackground',

  '@sm': {
    fontSize: '$3',
    lineHeight: '$medium',
  },

  '&:before': {
    content: '',
    position: 'absolute',
    left: '-1.25rem',
    top: '0',
    bottom: '0',
    width: '.25rem',
    backgroundColor: '$primary',
    borderRadius: '0 4px 4px 0',
  },
})

export const ShelfWrapper = styled('div', {})

import type { FC } from 'react'
import { useEffect } from 'react'

interface Props {
  alt?: string
  page?: string
}

const EventPromoView: FC<Props> = ({ alt, page }) => {
  useEffect(() => {
    if (!alt?.length) {
      return
    }

    window.dataLayer = window.dataLayer || []
    const { dataLayer } = window

    const promoView = {
      event: 'promoView',
      ecommerce: {
        promoView: {
          promotions: [
            {
              id: alt,
              name: alt,
              creative: alt,
              position: page,
            },
          ],
        },
      },
    }

    const hasItem = dataLayer.some(
      ({ event, ecommerce }) =>
        event === 'promoView' && ecommerce.promoView.promotions[0].id === alt
    )

    if (hasItem) {
      return
    }

    dataLayer.push(promoView)
  }, [alt, page])

  return null
}

export default EventPromoView

import { SuspenseViewport } from '@vtex/store-ui'
import React from 'react'
import { EventPromoClick } from 'src/components/common/EventPromoClick'
import ResponsiveImage from 'src/components/ui/ResponsiveImage'
import EventPromoView from 'src/views/home/event'

interface Props {
  image: {
    href: string
    alt: string
    sources: Array<{
      srcSet: string
    }>
  }
  position?: string
}

const Image = ({ image, position }: Props) => {
  return (
    <>
      <a
        href={image.href}
        onClick={() =>
          EventPromoClick(
            `Mosaico ${position} - ${image.alt}`,
            `Banners Internos da Página`
          )
        }
      >
        <ResponsiveImage
          alt={image.alt}
          desktop={image.sources[0].srcSet}
          mobile={image.sources[1].srcSet}
          lazy
        />
      </a>

      <SuspenseViewport fallback={null}>
        <EventPromoView
          alt={`Mosaico ${position} - ${image.alt}`}
          page="Banners Internos da Página"
        />
      </SuspenseViewport>
    </>
  )
}

export default Image

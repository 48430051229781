import React from 'react'
import { Icon } from '@naturacosmeticos/natds-react'
import type { IconName } from '@naturacosmeticos/natds-icons'
import { SectionTitle } from 'src/views/home/styled'

import {
  ButtonsContainer,
  CategoryButton,
  CategoryButtonsContainer,
} from './styled'

interface Props {
  buttons: Array<{
    categoryName: string
    categoryLink: string
  }>
}

const CategoryButtons = ({ buttons }: Props) => {
  return (
    <CategoryButtonsContainer>
      <SectionTitle>Categorias</SectionTitle>

      <ButtonsContainer>
        {buttons.map(({ categoryLink, categoryName }) => {
          const [category, icon] = categoryName.split(':')

          return (
            <CategoryButton key={categoryName} to={categoryLink}>
              <div>
                <Icon name={icon as IconName} size="semi" />
              </div>

              <p>{category}</p>
            </CategoryButton>
          )
        })}
      </ButtonsContainer>
    </CategoryButtonsContainer>
  )
}

export default CategoryButtons

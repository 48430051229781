import type { GatsbySeo } from 'gatsby-plugin-next-seo'
import type { ComponentPropsWithoutRef } from 'react'
import { useLocale } from '@vtex/gatsby-theme-store'
import useRepresentative from 'src/contexts/RepresentativeContext/useRepresentative'
import type { Props } from 'src/pages'

type Options = Props

type Return = ComponentPropsWithoutRef<typeof GatsbySeo>

export const useMetadata = (props: Options): Return => {
  const language = useLocale()
  const { isRepresentativeSelected } = useRepresentative()

  const siteUrl = `https://www.avon.com.br`
  const { cmsSeo } = props.data

  if (!cmsSeo?.seo) {
    return {}
  }

  return {
    title: String(cmsSeo.seo.siteMetadata?.title),
    description: String(cmsSeo.seo.siteMetadata?.description),
    titleTemplate: String(cmsSeo.seo.siteMetadata?.titleTemplate),
    language,
    noindex: isRepresentativeSelected,
    canonical: siteUrl,
    openGraph: {
      type: 'website',
      url: siteUrl,
      title: String(cmsSeo.seo.facebook?.title),
      description: String(cmsSeo.seo.facebook?.description),
    },
  }
}

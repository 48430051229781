import React, { useState } from 'react'
import { Icon, Link } from '@naturacosmeticos/natds-react'
import type { Product } from 'src/typings'
import ProductSlider from 'src/components/design-system/product/ProductSlider'
import { SectionTitle } from 'src/views/home/styled'
import { SuspenseViewport } from '@vtex/store-ui'
import loadable from '@loadable/component'

import Countdown from './Countdown'
import {
  ShelfContainer,
  ShelfDescription,
  ShelfHeaderContainer,
} from './styled'

const EventPromoView = loadable(() => import('src/views/home/event'))
const EventImpressions = loadable(
  () => import('src/components/common/EventImpressions')
)

const EventDetailsPage = loadable(
  () => import('src/components/common/EventDetailsPage')
)

interface Props {
  products: Product[]
  shelfData: {
    searchParams: {
      collection: string
      from: number
      to: number
      orderBy: string
      hideUnavailableItems: boolean
    }
    title: string
    description: string
    url: string
    endDate?: {
      day: number
      month: number
      hour: string
    }
    image?: Array<{ srcSet: string; link: string }>
  }
}

const HomeShelf = ({
  products,
  shelfData: { title, description, url, endDate, image, searchParams },
}: Props) => {
  const [hasCountdownEnded, setHasCountdownEnded] = useState(false)
  const isCountdownShelf = !!endDate && !hasCountdownEnded

  return (
    <ShelfContainer>
      <SuspenseViewport fallback={null}>
        <EventImpressions products={products} type="HOME" list={title} />
        <EventDetailsPage products={products} type="HOME" />
        <EventPromoView alt={title} page="Vitrine de Produtos" />
      </SuspenseViewport>

      <ShelfHeaderContainer isCountdownShelf={isCountdownShelf}>
        <div>
          <SectionTitle>{title}</SectionTitle>
          <ShelfDescription>{description}</ShelfDescription>
        </div>

        <Link
          href={url}
          text="Veja mais"
          iconPosition="right"
          IconComponent={
            <Icon
              name="filled-navigation-arrowright"
              size="small"
              color="link"
            />
          }
        />

        {isCountdownShelf && (
          <Countdown
            endDate={endDate}
            setHasCountdownEnded={setHasCountdownEnded}
          />
        )}
      </ShelfHeaderContainer>

      <ProductSlider
        products={products}
        shelfImage={image}
        searchParams={searchParams}
        title={title}
      />
    </ShelfContainer>
  )
}

export default HomeShelf

import React from 'react'
import useRepresentative from 'src/contexts/RepresentativeContext/useRepresentative'

import CarouselBanners from './CarouselBanners'

interface Props {
  sections:
    | Array<{
        name: string
        data: {
          allItems: Array<{
            alt: string
            href: string
            sources: Array<{ media: string; srcSet: string }>
          }>
        }
      }>
    | undefined
}

const HomeCarousel = ({ sections }: Props) => {
  const { isRepresentativeSelected } = useRepresentative()

  const carouselName = isRepresentativeSelected
    ? 'RepresentativeCarousel'
    : 'Carousel'

  const carousel = sections?.find((section) => section.name === carouselName)

  if (!carousel) {
    return null
  }

  return (
    <CarouselBanners banners={carousel?.data.allItems} isCarouselDesignSystem />
  )
}

export default HomeCarousel

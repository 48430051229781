import React, {
  useCallback,
  useEffect,
  useState,
  Children,
  cloneElement,
} from 'react'
import { SliderArrowLeft, SliderArrowRight } from '@vtex/store-ui'
import { useSwipeable } from 'react-swipeable'
import { Icon } from '@naturacosmeticos/natds-react'
import type { FC, ReactElement } from 'react'

import { ArrowsContainer, CarouselContainer, Dots, Item } from './styled'

interface CarouselProps {
  timeout?: number
  isCarouselDesignSystem?: boolean
}

export const CarouselItem: FC = ({ children }) => {
  return <Item className="carousel-item">{children}</Item>
}

const Carousel: FC<CarouselProps> = ({
  children,
  timeout = 8000,
  isCarouselDesignSystem,
}) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const totalItems = Children.count(children)

  const updateIndex = useCallback(
    (newIndex: number) => {
      if (newIndex < 0) {
        newIndex = 0
      }

      if (newIndex >= Children.count(children)) {
        newIndex = Children.count(children) - 1
      }

      setActiveIndex(newIndex)
    },
    [children]
  )

  useEffect(() => {
    const interval = setInterval(() => {
      updateIndex(activeIndex + 1 === totalItems ? 0 : activeIndex + 1)
    }, timeout)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [activeIndex, timeout, totalItems, updateIndex])

  const handlers = useSwipeable({
    onSwipedLeft: () =>
      updateIndex(activeIndex === totalItems - 1 ? 0 : activeIndex + 1),
    onSwipedRight: () =>
      updateIndex(activeIndex === 0 ? totalItems - 1 : activeIndex - 1),
  })

  const handleNext = () => {
    return updateIndex(activeIndex === totalItems - 1 ? 0 : activeIndex + 1)
  }

  const handlePrevious = () => {
    return updateIndex(activeIndex === 0 ? totalItems - 1 : activeIndex - 1)
  }

  return (
    <CarouselContainer {...handlers}>
      <div
        className="inner"
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {Children.map(children as ReactElement, (child: ReactElement) => {
          return cloneElement(child, { width: '100%' })
        })}
      </div>

      <ArrowsContainer
        className="arrows"
        isDesignSystemArrows={isCarouselDesignSystem}
      >
        {isCarouselDesignSystem ? (
          <>
            <button
              onClick={handlePrevious}
              aria-label="Previous Carousel Image"
            >
              <Icon
                name="outlined-navigation-directionright"
                color="mediumEmphasis"
              />
            </button>

            <button onClick={handleNext} aria-label="Next Carousel Image">
              <Icon
                name="outlined-navigation-directionleft"
                color="mediumEmphasis"
              />
            </button>
          </>
        ) : (
          <>
            <SliderArrowLeft
              onClick={handlePrevious}
              aria-label="Previous Carousel Image"
            />

            <SliderArrowRight
              onClick={handleNext}
              aria-label="Next Carousel Image"
            />
          </>
        )}
      </ArrowsContainer>

      <Dots
        aria-label="Slider pagination dots"
        role="group"
        className="dots-container"
        isDesignSystemDots={isCarouselDesignSystem}
      >
        {Children.map(children, (_, index) => (
          <button
            onClick={() => updateIndex(index)}
            className={`${index === activeIndex ? 'active' : ''}`}
            tabIndex={0}
            aria-label={`Dot ${index + 1} of ${totalItems}`}
          />
        ))}
      </Dots>
    </CarouselContainer>
  )
}

export default Carousel

/* eslint-disable prettier/prettier */
import React from 'react'

import Image from './Image'
import { BigImage, MosaicContainer, SmallImages } from './styled'

type MosaicBanners = {
  href: string
  alt: string
  sources: Array<{
    media: string
    srcSet: string
  }>
}

type Props = {
  allItems: MosaicBanners[]
}

const Mosaic = ({ allItems }: Props) => {
  const [firstImage, secondImage, thirdImage, fourthImage] = allItems

  return (
    <MosaicContainer>
      <BigImage>
        <Image image={firstImage} position="1" />
      </BigImage>

      <div>
        <SmallImages>
          <Image image={secondImage} position="2" />
          <Image image={thirdImage} position="3" />
        </SmallImages>

        <Image image={fourthImage} position="4" />
      </div>
    </MosaicContainer>
  )
}

export default Mosaic

import { styled } from 'src/gatsby-theme-stitches/config'

export const BenefitsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '.9375rem',

  '.keen-slider__slide': {
    paddingBottom: '1px',
  },
})

export const BenefitsBannerContainer = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontFamily: '$roboto',
  color: '$highlight',
  mt: '2rem',

  a: {
    all: 'unset',
    cursor: 'pointer',
  },

  '.pointer': {
    cursor: 'pointer',
  },

  '.benefit': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '$onPrimary',
    boxShadow: '0px 1px 1px #00000029',
    borderRadius: '4px',
    py: '0.5rem',
    width: '16.25rem',
    height: '5rem',
    position: 'relative',
    zIndex: '10',

    '@media(max-width: 1280px)': {
      flexDirection: 'column',
      width: '11.4375rem',
      height: '8.75rem',
      textAlign: 'center',
      padding: '0px 1.25rem',
    },

    '@onlyMobile': {
      width: '100%',
      flexDirection: 'row',
      padding: '.875rem',
      textAlign: 'left',
      height: '5.5rem',
    },
  },
})

export const TextContainer = styled('div', {
  ml: '.9375rem',

  '@media(max-width: 1280px)': {
    ml: '0',
  },

  '@onlyMobile': {
    ml: '1.25rem',
  },
})

export const Text = styled('p', {
  fontSize: '.75rem',
  margin: 0,

  variants: {
    isBoldText: {
      true: {
        fontSize: '1.125rem',
        fontWeight: '$bold',
        letterSpacing: '0.2px',

        '@media(max-width: 1280px)': {
          fontSize: '1rem',
        },
      },
    },
  },
})

export const DotsContainer = styled('div', {
  display: 'none',

  '@onlyMobile': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
  },
})

export const Dots = styled('div', {
  cursor: 'pointer',
  display: 'inline-block',
  padding: '0.25rem',
  border: 0,
  borderRadius: '50%',
  outline: '0',
  height: '.5rem',
  width: '.5rem',
  backgroundColor: '$lowEmphasis',

  variants: {
    isActive: {
      true: {
        backgroundColor: '$primary',
      },
    },
  },
})

import { styled } from 'src/gatsby-theme-stitches/config'

export const MosaicContainer = styled('div', {
  mt: '1.5rem',
  display: 'flex',
  gap: '2px',
  justifyContent: 'center',

  '@sm': {
    flexDirection: 'column',
  },

  img: {
    width: '100%',
  },

  '> div': {
    display: 'flex',
    gap: '2px',

    '@sm': {
      flexDirection: 'row-reverse',
      justifyContent: 'center',

      '&:last-child': {
        '& > a': {
          width: '50%',
        },
      },
    },
  },

  a: {
    display: 'flex',
  },
})

export const BigImage = styled('div', {
  '@sm': {
    a: {
      width: '100%',
    },
  },
})

export const SmallImages = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',

  a: {
    width: 'auto',
    height: '50%',
    objectFit: 'contain',
  },

  '@sm': {
    width: '50%',

    a: {
      height: '50%',
      width: '100%',

      picture: {
        height: '100%',
      },
    },
  },
})

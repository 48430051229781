import { useEffect, useState } from 'react'

const getReturnValues = (
  countDown: number,
  options?: { showDay?: boolean }
) => {
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24))
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  )

  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000)

  if (!options?.showDay) {
    return [
      String(hours).padStart(2, '0'),
      String(minutes).padStart(2, '0'),
      String(seconds).padStart(2, '0'),
    ]
  }

  return [
    String(days).padStart(2, '0'),
    String(hours).padStart(2, '0'),
    String(minutes).padStart(2, '0'),
    String(seconds).padStart(2, '0'),
  ]
}

export const useCountdown = (
  targetDate: string,
  options?: { showDay?: boolean }
) => {
  const countdownDate = new Date(targetDate).getTime()

  const [countdown, setCountdown] = useState(
    countdownDate - new Date().getTime()
  )

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(countdownDate - new Date().getTime())
    }, 1000)

    return () => clearInterval(interval)
  }, [countdownDate])

  return getReturnValues(countdown, { showDay: options?.showDay ?? true })
}

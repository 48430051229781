import { styled } from 'src/gatsby-theme-stitches/config'

export const RepresentativeCarouselContainer = styled('div', {
  position: 'relative',
  my: '2rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',

  a: {
    display: 'flex',
    flexGrow: '1',
    minHeight: '16.1875rem',
  },

  img: {
    width: '100%',
    height: '100%',
  },
})

export const DotsContainer = styled('div', {
  display: 'none',

  '@sm': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
  },
})

export const Dots = styled('div', {
  cursor: 'pointer',
  display: 'inline-block',
  padding: '0.25rem',
  border: 0,
  borderRadius: '50%',
  outline: '0',
  height: '.5rem',
  width: '.5rem',
  backgroundColor: '$lowEmphasis',

  variants: {
    isActive: {
      true: {
        backgroundColor: '$primary',
      },
    },
  },
})

import { styled } from 'src/gatsby-theme-stitches/config'

export const ShelfContainer = styled('div', {
  mt: '2rem',
})

export const ShelfHeaderContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  color: '$onSurface',
  fontFamily: '$roboto',
  mb: '1rem',

  '@sm': {
    alignItems: 'flex-start',
  },

  span: {
    mr: '1rem',

    '@sm': {
      margin: 0,

      a: {
        fontSize: '$2',
        mr: '.25rem',
      },
    },
  },

  variants: {
    isCountdownShelf: {
      true: {
        gap: '1rem',

        '@sm': {
          flexWrap: 'wrap',
        },

        '> span': {
          display: 'none',

          '@sm': {
            display: 'block',
          },
        },
      },
    },
  },
})

export const ShelfDescription = styled('p', {
  fontSize: '$2',
  lineHeight: '$medium',
  mt: '.5rem',
  ml: '1.25rem',

  '@sm': {
    fontSize: '$1',
    lineHeight: '$large',
  },
})

export const ShelfCountdownContainer = styled('div', {
  backgroundColor: '$primary',
  borderRadius: '15px 0 15px 0',
  color: '$white',
  fontFamily: '$roboto',
  fontSize: '.875rem',
  padding: '.75rem 1.875rem',
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  flexWrap: 'wrap',

  '@sm': {
    width: '100%',
    justifyContent: 'center',
    padding: '.625rem 1.25rem',
    gap: '0.5rem',
  },

  div: {
    display: 'flex',
    gap: '.625rem',
    fontWeight: '$bold',

    span: {
      margin: 0,
      fontSize: '1.25rem',
    },
  },
})

import { styled } from 'src/gatsby-theme-stitches/config'
import { Link } from 'gatsby'

export const CarouselContainer = styled('div', {
  position: 'relative',

  '&:hover': {
    '.arrows': { opacity: '1' },
  },

  '.arrows': {
    opacity: '0',
    transition: 'opacity 0.3s',

    button: {
      position: 'absolute',
      top: '50%',
      transform: 'translate(0, -50%)',
      cursor: 'pointer',
      backgroundColor: '$white',
      boxShadow: '0px 4px 5px #00000024',
      border: '1px solid $colors$primary',
      borderRadius: '50%',
      width: '3rem',
      height: '3rem',
      display: 'grid',
      placeItems: 'center',
      transition: 'all 0.2s',

      '&:first-of-type': {
        left: '7%',
      },

      '&:last-of-type': {
        right: '7%',
      },

      '&:hover': {
        filter: 'brightness(0.75)',
      },
    },
  },

  '.dots': {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
    gap: '1rem',

    '.dot': {
      border: 'none',
      size: '0.5rem',
      backgroundColor: '$lowEmphasis',
      cursor: 'pointer',
      borderRadius: '50%',

      '&.active': {
        backgroundColor: '$primary',
      },
    },
  },

  '.keen-slider__slide': {
    minWidth: '84.375rem',
  },
})

export const CarouselItemLink = styled(Link, {
  display: 'flex',
  width: '100%',
})

export const CarouselItemImage = styled('picture', {
  width: '100%',

  img: {
    display: 'flex',
    height: 'auto',
    width: '100%',

    '@sm': {
      width: '100vw',
    },
  },
})

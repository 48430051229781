import React from 'react'
import type { PageProps } from 'gatsby'
import type { HomePageQueryQuery } from 'src/pages/__generated__/HomePageQuery.graphql'
import { SuspenseViewport } from '@vtex/store-ui'
import loadable from '@loadable/component'
import EventPageView from 'src/components/common/EventPageView'
import HomeCarousel from 'src/components/home/CarouselBanners'
import BenefitsBanner from 'src/components/home/BenefitsBanner'
import CategoryButtons from 'src/components/home/CategoryButtons'
import HomeShelf from 'src/components/home/HomeShelf'
import Mosaic from 'src/components/home/Mosaic'
import type { Product } from 'src/typings'
import RepresentativeCarousel from 'src/components/home/RepresentativeCarousel'
import useStoreType from 'src/contexts/StoreTypeContext/useStoreType'

import { HomeContainer, HomeWrapper } from './styled'

const DiscountBanner = loadable(
  () => import('src/components/home/DiscountBanner')
)

const BrandSlider = loadable(() => import('src/components/home/BrandSlider'))

const SocialProof = loadable(() => import('src/components/home/SocialProof'))

const CustomEvent = loadable(() => import('src/components/common/CustomEvent'))
const EventView = loadable(() => import('src/components/common/EventView'))

type Props = PageProps<HomePageQueryQuery>

interface ShelfData {
  name: string
  data: {
    searchParams: {
      collection: string
      from: number
      to: number
      orderBy: string
      hideUnavailableItems: boolean
    }
    title: string
    description: string
    url: string
    endDate?: {
      day: number
      month: number
      hour: string
    }
    image?: Array<{ srcSet: string; link: string }>
  }
}

const HomeView = ({
  data: {
    cmsHome,
    cmsHomeColaborador,
    cmsSeo,
    vtex: { firstShelfProducts, secondShelfProducts, thirdShelfProducts },
  },
}: Props) => {
  const { isCollaboratorStore } = useStoreType()
  const homeContent = isCollaboratorStore ? cmsHomeColaborador : cmsHome

  if (!homeContent) {
    return null
  }

  const [firstShelfData, secondShelfData, thirdShelfData]: ShelfData[] =
    homeContent.sections.filter((section) => section.name.includes('Shelf'))

  const categoryButtons = homeContent.sections.find(
    (section) => section.name === 'CategoriesMenu'
  )

  const discountBanner = homeContent.sections.find(
    (section) => section.name === 'HomeMiddleBanner'
  )

  const brandSlider = homeContent.sections.find(
    (section) => section.name === 'Banners'
  )

  const mosaic = homeContent.sections.find(
    (section) => section.name === 'Mosaic'
  )

  const [, representativeCarousel] = homeContent.sections.filter(
    (section) => section.name === 'Carousel'
  )

  return (
    <HomeWrapper>
      <EventPageView
        type="HOME"
        title={cmsSeo?.seo?.siteMetadata?.title ?? 'Avon'}
      />

      <SuspenseViewport fallback={null}>
        <CustomEvent type="HOME" />
        <EventView type="HOME" />
      </SuspenseViewport>

      <HomeCarousel sections={homeContent.sections} />

      <HomeContainer>
        <BenefitsBanner />

        {categoryButtons && (
          <CategoryButtons buttons={categoryButtons.data.buttons} />
        )}

        <HomeShelf
          products={firstShelfProducts as unknown as Product[]}
          shelfData={firstShelfData.data}
        />

        {discountBanner && <DiscountBanner data={discountBanner.data} />}

        <HomeShelf
          products={secondShelfProducts as unknown as Product[]}
          shelfData={secondShelfData.data}
        />

        {brandSlider && <BrandSlider banners={brandSlider.data.banners} />}

        {mosaic && <Mosaic {...mosaic?.data} />}

        <HomeShelf
          products={thirdShelfProducts as unknown as Product[]}
          shelfData={thirdShelfData.data}
        />

        {representativeCarousel && (
          <RepresentativeCarousel
            banners={representativeCarousel.data.allItems}
          />
        )}

        <SocialProof />
      </HomeContainer>
    </HomeWrapper>
  )
}

export default HomeView

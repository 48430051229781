import React, { useState } from 'react'
import { Icon } from '@naturacosmeticos/natds-react'
import { useKeenSlider } from 'keen-slider/react'
import { Link } from 'gatsby'

import {
  BenefitsBannerContainer,
  Text,
  TextContainer,
  Dots,
  DotsContainer,
  BenefitsContainer,
} from './styled'

const BenefitsBanner = () => {
  const handleGoToRegisterPosition = () => {
    const element = document?.querySelector('.news-letter')

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    }
  }

  const benefits = [
    {
      id: 'Frete Grátis',
      component: (
        <Link to="/frete-gratis-119">
          <div className="benefit">
            <Icon
              size="semiX"
              color="primary"
              name="outlined-place-runningtruck"
            />
            <TextContainer>
              <Text>
                Compras acima de <strong>R$ 119,90</strong>
              </Text>
              <Text isBoldText>Frete Grátis</Text>
              <Text>Para as regiões Sul e Sudeste.</Text>
            </TextContainer>
          </div>
        </Link>
      ),
    },
    {
      id: 'Sem juros',
      component: (
        <div className="benefit">
          <Icon
            size="semiX"
            color="primary"
            name="outlined-finance-creditcard"
          />
          <TextContainer>
            <Text>Tudo em até</Text>
            <Text isBoldText>6X Sem Juros</Text>
            <Text>
              e parcela mínima de <strong>R$ 10.</strong>
            </Text>
          </TextContainer>
        </div>
      ),
    },
    {
      id: 'Cupom Desconto',
      component: (
        <Link to="/cupom-de-desconto">
          <div className="benefit">
            <Icon
              size="semiX"
              color="primary"
              name="outlined-finance-moneypaper"
            />

            <TextContainer>
              <Text>Milhares de</Text>
              <Text isBoldText>Cupons de Desconto</Text>
              <Text>clique e resgate os seus.</Text>
            </TextContainer>
          </div>
        </Link>
      ),
    },
    {
      id: 'Desconto',
      component: (
        <div
          onClick={handleGoToRegisterPosition}
          aria-hidden="true"
          className="benefit pointer"
        >
          <Icon size="semiX" color="primary" name="outlined-finance-discount" />

          <TextContainer>
            <Text>Cadastre-se e ganhe</Text>
            <Text isBoldText>10% de desconto</Text>
            <Text>na sua primeira compra</Text>
          </TextContainer>
        </div>
      ),
    },
  ]

  const [currentSlide, setCurrentSlide] = useState(0)

  const [sliderRef, instanceRef] = useKeenSlider({
    breakpoints: {
      '(max-width: 768px)': {
        slides: { perView: 1, spacing: 16 },
      },
      '(min-width: 768px)': {
        slides: { perView: 4, spacing: 1 },
      },
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
  })

  return (
    <BenefitsContainer>
      <div className="keen-slider" ref={sliderRef}>
        <BenefitsBannerContainer>
          {benefits.map((benefit) => (
            <div className="keen-slider__slide" key={benefit.id}>
              {benefit.component}
            </div>
          ))}
        </BenefitsBannerContainer>
      </div>

      <DotsContainer role="group" aria-label="Slider pagination dots">
        {[...Array(benefits.length).keys()].map((_, index) => (
          <Dots
            isActive={currentSlide === index}
            key={index}
            tabIndex={0}
            onClick={() => instanceRef.current?.moveToIdx(index)}
            role="button"
            aria-label={`Dot ${index + 1}`}
            data-testid="paginationDot"
          />
        ))}
      </DotsContainer>
    </BenefitsContainer>
  )
}

export default BenefitsBanner

import type { Dispatch, SetStateAction } from 'react'
import React from 'react'
import { useCountdown } from 'src/hooks/useCountdown'

import { ShelfCountdownContainer } from './styled'

type EndDateType = {
  day: number
  month: number
  hour: string
}

interface Props {
  endDate: EndDateType
  setHasCountdownEnded: Dispatch<SetStateAction<boolean>>
}

const dateFormatter = ({ day, hour, month }: EndDateType) => {
  const timeFormatted = hour.split(':')
  const date = new Date()

  return new Date(
    date.getFullYear(),
    month,
    day,
    Number(timeFormatted[0]),
    Number(timeFormatted[1]),
    0
  ).toString()
}

const Countdown = ({ endDate, setHasCountdownEnded }: Props) => {
  const dateFormatted = dateFormatter(endDate)
  const [days, hours, minutes, seconds] = useCountdown(dateFormatted)

  if (Number(seconds) <= 0) {
    setHasCountdownEnded(true)
  }

  return (
    <ShelfCountdownContainer>
      <p>Encerra em: </p>

      <div>
        <p>
          <span>{days}</span>
          {days === '01' || days === '00' ? 'dia' : 'dias'}
        </p>
        <p>
          <span>{hours}</span>H
        </p>
        <p>
          <span>{minutes}</span>M
        </p>
        <p>
          <span>{seconds}</span>S
        </p>
      </div>
    </ShelfCountdownContainer>
  )
}

export default Countdown
